export default [
  {
    id: 1,
    name: 'Daniela Mayumi Kita',
    role: 'Bióloga',
    photoSrc: require('../collaborators/daniela-mayumi.jpeg'),
    alt: 'Daniela Kita é descendente de japoneses. Ela tem pele clara, rosto arredondado, olhos castanhos e cabelos pretos e lisos na altura dos ombros, repartidos de lado. Seu sorriso largo ressalta suas maçãs do rosto salientes e seus olhos levemente puxados. À frente de uma parede branca, Daniela usa óculos de armação arredondada preta, uma blusa também preta no estilo baby look e uma correntinha fina prateada.',
    contactLinks: {
      webLink: 'http://lattes.cnpq.br/4021509989247853',
      linkedin: 'https://www.linkedin.com/in/daniela-kita/'
    }
  },
  {
    id: 2,
    name: 'Mariana Pupo Cassinelli',
    role: 'Bióloga',
    photoSrc: require('../collaborators/mariana-pupo.jpeg'),
    alt: 'Mariana Cassinelli é magra, tem pele clara, olhos castanhos e cabelos também castanhos, lisos e longos, repartidos de lado. Seu rosto é oval e seu nariz bem fino. À frente de uma parede bege, ela exibe um sorriso discreto. Mariana usa uma regata preta, pequenos brincos de argola prateados e um colar com um pingente prateado de tartaruga.',
    contactLinks: {
      instagram: 'https://instagram.com/mari_pupo',
      linkedin: 'https://www.linkedin.com/in/mariana-pupo-cassinelli-0515591a6/',
      webLink: 'http://lattes.cnpq.br/6936236029643012'
    }
  },
  {
    id: 3,
    name: 'Denner Dias Barros',
    role: 'Orientador',
    photoSrc: require('../collaborators/denner-dias.jpg'),
    alt: 'Denner Barros tem pele clara, rosto arredondado, olhos pretos e cabelos, bigode e barba também pretos e curtos. Ele usa uma camisa social azul de mangas compridas e está à frente de um painel de vidro através do qual podem ser vistos alguns carros num estacionamento.',
    contactLinks: {
      webLink: 'https://bv.fapesp.br/pt/pesquisador/677872/denner-dias-barros/'
    }
  },
  {
    id: 4,
    name: 'Miriam Godoy Penteado',
    role: 'Orientadora',
    photoSrc: require('../collaborators/miriam-godoy.jpg'),
    alt: 'Miriam Penteado tem pele clara, olhos castanhos e cabelos grisalhos e ondulados na altura dos ombros, repartidos de lado. Seu sorriso simpático destaca suas bochechas e discretas linhas de expressão no canto dos olhos. À frente de uma parede branca, ela usa camisa preta de mangas compridas e decote em V.',
    contactLinks: {
      email: 'miriam-godoy.penteado@unesp.br',
      instagram: 'https://www.instagram.com/miriam.g.penteado/',
      webLink: 'http://lattes.cnpq.br/4099992332439295'
    }
  },
  {
    id: 5,
    name: 'Osvaldo Margato',
    role: 'Desenvolvedor',
    photoSrc: require('../collaborators/osvaldo-margato.png'),
    alt: 'Osvaldo Margato é magro, tem pele clara, olhos castanhos e cabelos também castanhos, curtos e ondulados. Ele está à frente de uma parede branca e seu sorriso discreto revela covinhas. Osvaldo usa uma camiseta preta básica.',
    contactLinks: {
      github: 'https://www.github.com/margato',
      linkedin: 'https://www.linkedin.com/in/margato/',
      webLink: 'https://margato.netlify.com'
    }
  },
  {
    id: 6,
    name: 'Matheus Pereira de Souza',
    role: 'Desenvolvedor',
    photoSrc: require('../collaborators/matheus-pereira.jpg'),
    alt: 'Matheus de Souza é magro, tem pele clara, olhos esverdeados, bigode loiro ralo e cabelo loiro escuro cortado bem baixinho. Sério, ele está à frente de uma parede branca e usa uma camiseta preta básica.',
    contactLinks: {
      github: 'https://github.com/mathsouza98',
      linkedin: 'https://www.linkedin.com/in/matheus-pereira-de-souza-1a38a963/'
    }
  },
  {
    id: 7,
    name: 'Manoela Nunes de Jesus',
    role: 'Audiodescritora',
    photoSrc: require('../collaborators/manoela-nunes.jpeg'),
    alt: 'Manoela de Jesus é magra, tem pele morena, olhos castanhos e cabelos também castanhos, longos e ondulados, repartidos ao meio e arrumados sobre um dos ombros. Com um semblante sério, Manoela está à frente de uma parede branca e usa uma blusa rosa de gola alta sem mangas.',
    contactLinks: {
      email: 'manoelanunesdejesus@hotmail.com',
      instagram: 'https://instagram.com/manoela.com.o',
      webLink: 'http://lattes.cnpq.br/3186024115083895'
    }
  },
  {
    id: 8,
    name: 'Manoela Cristina Correia Carvalho da Silva',
    role: 'Audiodescritora',
    photoSrc: require('../collaborators/manoela-cristina.jpeg'),
    alt: 'Manoela da Silva tem pele clara, olhos castanhos e cabelos também castanhos e cacheados, na altura do pescoço, repartidos de lado. Ela exibe um largo sorriso que acentua suas bochechas salientes. Manoela está à frente de uma parede branca e usa óculos marrons, batom avermelhado e uma blusa estampada em tons de marrom com detalhes em azul e decote em V.',
    contactLinks: {
      email: 'mcsilva@ufba.br',
      facebook: 'http://facebook.com/people/Manoela-Cristina-Silva/100012810244041',
      webLink: 'http://lattes.cnpq.br/1401867652891934'
    }
  },
  {
    id: 9,
    name: 'Elaine Alves Soares',
    role: 'Audiodescritora',
    photoSrc: require('../collaborators/elaine-alves.jpeg'),
    alt: 'Elaine Soares é magra, tem pele negra, olhos pretos, e cabelos com longas tranças marrons tipo twist que vão quase até a cintura. Ela exibe um sorriso discreto e está em frente a de uma parede branca. Elaine usa uma camiseta preta básica.',
    contactLinks: {
      email: 'elaineas002@gmail.com',
      webLink: 'http://lattes.cnpq.br/2866199030578367'
    }
  },
  {
    id: 10,
    name: 'Andressa Queiroz',
    role: 'Audiodescritora',
    photoSrc: require('../collaborators/andressa-queiroz.jpeg'),
    alt: 'Andressa Queiroz tem pele morena clara, rosto arredondado, olhos castanhos e cabelos também castanhos e ondulados, na altura dos ombros, repartidos de lado. À frente de uma parede branca, ela exibe um sorriso discreto. Andressa usa óculos de armação preta, batom rosa claro, uma blusa de manga longa preta com decote em V e um colar com pingente prateado do personagem Darth Vader do filme Star Wars.',
    contactLinks: {
      linkedin: 'https://www.linkedin.com/in/teacher-andressa-queiroz/',
      email: 'contatopro.andressaqueiroz@gmail.com',
      webLink: 'http://lattes.cnpq.br/5020846389839348'
    }
  },
  {
    id: 11,
    name: 'Manoel Negraes',
    role: 'Consultor em audiodescrição',
    photoSrc: require('../collaborators/manoel-negraes.jpeg'),
    alt: 'Manoel Negraes tem pele clara, olhos castanhos, cabelos grisalhos curtos e ondulados, bigode e barba curtos e pretos, com alguns fios brancos. Seu sorriso amplo deixa seus olhos bem pequenos e revela discretas linhas de expressão no canto dos olhos. Manoel está numa sala de estar de paredes brancas com alguns quadros e usa uma camiseta vermelha básica.',
    contactLinks: {
      email: 'manoel.mid@gmail.com',
      facebook: 'https://www.facebook.com/manoel.negraes'
    }
  },
  {
    id: 12,
    name: 'Ednilson Sacramento',
    role: 'Consultor em audiodescrição',
    photoSrc: require('../collaborators/ednilson-sacramento.jpeg'),
    alt: 'Ednilson Sacramento é magro, tem pele morena, cabelos grisalhos cortados bem curtinhos e barba e bigode também grisalhos por fazer. Ele exibe um sorriso largo que deixa seus olhos bem fechados e destaca maçãs do rosto salientes e linhas de expressão no canto dos olhos. Ednilson está ao ar livre, à frente de várias árvores desfocadas, e usa uma camiseta azul marinho básica.',
    contactLinks: {
      email: 'ednilsonsacramento@gmail.com',
      webLink: 'https://linktr.ee/ednilsonsacramento'
    }
  },
  {
    id: 13,
    name: 'Jessica Pereira',
    role: 'Intérprete de Libras',
    photoSrc: require('../collaborators/jessica-pereira.jpeg'),
    alt: 'Jessica Pereira tem pele clara, olhos castanhos e cabelos loiros e lisos na altura dos ombros, repartidos de lado. Em frente a uma parede branca, ela exibe largo sorriso e maquiagem suave, com batom e blush levemente alaranjados. Jéssica usa pequenos brincos pretos e uma blusa também preta de mangas compridas.',
    contactLinks: {
      instagram: 'https://www.instagram.com/jepereira14/'
    }
  },
  {
    id: 14,
    name: 'Paula Cristina Gutierres',
    role: 'Intérprete de Libras',
    photoSrc: require('../collaborators/paula-cristina.jpeg'),
    alt: 'Paula Gutierres é magra, tem pele clara, olhos castanhos e cabelos também castanhos, lisos e longos, repartidos de lado. Ela tem traços finos e sobrancelhas grossas. Séria, numa sala de paredes brancas, em frente a uma pintura de flores, Paula usa uma blusa branca com uma listra preta na gola.',
    contactLinks: {
      email: 'paulagutietr@gmail.com',
      webLink: 'http://lattes.cnpq.br/5609224465125423'
    }
  },
  {
    id: 15,
    name: 'Escola Severino Fabriani',
    role: 'Intérprete de Libras',
    photoSrc: require('../collaborators/escola-libras.jpg'),
    alt: 'A Escola Severino Fabriani é representada por seu logo. Sobre um fundo com grandes losangos em tons de azul, está o símbolo da escola no formato de um coração. O contorno do lado esquerdo é delineado pela frase “Instituto Severino Fabriani para crianças surdas” em letras azuis e o do lado direito por duas faixas nas cores azul-claro e azul-escuro. No centro do coração, estão o contorno do rosto de duas crianças, uma estrela cadente e o desenho de duas mãos, uma sobre a outra. Abaixo do coração, em verde, está escrito “Polo Fael”.',
    contactLinks: {}
  },
  {
    id: 16,
    name: 'Guilherme Soares',
    role: 'Dublador - Rudá e Sapo',
    photoSrc: require('../collaborators/guilherme-soares.jpeg'),
    alt: 'Guilherme Soares tem pele clara, olhos verdes, cabelos castanhos, curtos e lisos, com uma franja lateral sobre a testa, e barba e bigode também castanhos e curtos. Sério, Guilherme está em um quarto de paredes brancas com uma cama e uma sapateira ao fundo. Ele usa óculos de armação quadrada preta, uma camiseta preta básica e uma correntinha de cobre.',
    contactLinks: {
      email: 'guilherme2soares@hotmail.com',
      instagram: 'https://www.instagram.com/gui6thunder/?hl=pt-br',
      facebook: 'https://www.facebook.com/gui2soares'
    }
  },
  {
    id: 17,
    name: 'Larissa Mota Rodrigues',
    role: 'Dubladora - Iara',
    photoSrc: require('../collaborators/pessoa-anonima.png'),
    alt: 'Larissa Rodrigues é representada por um desenho em preto sobre um fundo branco. O desenho é a silhueta de uma mulher de cabelos lisos, na altura dos ombros, com uma franja lateral, usando uma camiseta preta básica.',
    contactLinks: {}
  },
  {
    id: 18,
    name: 'Aldo Mendonça',
    role: 'Animador 2D',
    photoSrc: require('../collaborators/aldo-mendonca.jpg'),
    alt: 'Aldo Mendonça é magro, tem pele clara, olhos pretos e cabelos castanhos, curtos e ondulados. Ele tem rosto comprido, cavanhaque cerrado e lábios finos. Sério, ele está ao ar livre, com árvores e uma trilha desfocadas ao fundo. Aldo carrega uma mochila de alças pretas nas costas e usa óculos de armação quadrada preta, pequenos brincos prateados e camiseta branca básica com estampa discreta em tons de cinza.',
    contactLinks: {
      email: 'victor_aldo123@hotmail.com',
      instagram: 'https://www.instagram.com/aldo_mendoncaa/'
    }
  },
  {
    id: 19,
    name: 'Daysa Yoshida',
    role: 'Designer',
    photoSrc: require('../collaborators/daysa-yoshida.jpeg'),
    alt: 'Daysa Yoshida é descendente de japoneses. Ela tem pele clara, olhos pretos levemente puxados e cabelos também pretos, longos e lisos, repartidos de lado, com uma única mecha loira que vai da raiz, próxima ao pescoço, até as pontas. Em frente a uma parede branca, Daysa sorri discretamente. Ela usa óculos de meia armação preta e uma camisa branca com estampa floral verde, rosa e amarela.',
    contactLinks: {
      behance: 'https://behance.net/daysayoshida',
      email: 'daysayoshida@gmail.com',
      instagram: 'https://instagram.com/daysanamie'
    }
  },
  {
    id: 20,
    name: 'Larissa Gonçalves',
    role: 'Revisora de texto',
    photoSrc: require('../collaborators/larissa-goncalves.png'),
    alt: 'Larissa Gonçalves é magra, tem pele clara, olhos castanhos e cabelos também castanhos e lisos, na altura do pescoço, repartidos de lado. Num cômodo de paredes brancas, com um espelho ao fundo, ela exibe um sorriso discreto que acentua suas bochechas. Larissa usa maquiagem leve, com delineado discreto e sombra e batom claros, pequenos brincos dourados e uma blusa preta com estampa de bolinhas brancas e um colarinho também branco.',
    contactLinks: {
      instagram: 'https://www.instagram.com/laris_forster/',
      linkedin: 'https://linkedin.com/in/larissa-goncalves-forster',
      webLink: 'http://lattes.cnpq.br/6357277233762372'
    }
  },
  {
    id: 21,
    name: 'Jorge Bernal',
    role: 'Consultor de questões indígenas',
    photoSrc: require('../collaborators/jorge-bernal.jpg'),
    alt: 'Jorge Bernal é magro, tem pele clara e cabelos loiros, curtos e lisos, raspados nas laterais. Apoiado na mureta branca de um píer, com o mar de águas calmas ao fundo, ele sorri. Jorge usa óculos escuros, uma camiseta básica vermelha, um piercing e um pequeno alargador na orelha esquerda e pulseiras artesanais indígenas nas cores verde, laranja e vermelho.',
    contactLinks: {
      webLink: 'http://lattes.cnpq.br/0769463019868997'
    }
  },
  {
    id: 22,
    name: 'Jailson Moreira',
    role: 'Revisor de conteúdo',
    photoSrc: require('../collaborators/jailson-moreira.jpg'),
    alt: 'Jailson Moreira tem pele morena, testa larga, cabelos escuros cortados rente à raiz e entradas. Sua barba e bigode são escuros e fartos. Sério e de perfil, ele está em frente a uma parede clara.  A fotografia de Jailson tem um filtro que a deixa com tons amarronzados, lembrando uma foto antiga.',
    contactLinks: {}
  },
  {
    id: 23,
    name: 'Amanda Queiroz Moura',
    role: 'Colaboradora na idealização',
    photoSrc: require('../collaborators/amanda-queiroz.jpeg'),
    alt: 'Amanda Moura tem pele morena, olhos castanhos e cabelos também castanhos e cacheados, na altura dos ombros. Seu sorriso simpático acentua suas bochechas e seu batom rosado. Em frente a uma parede branca, Amanda usa óculos de armação quadrada preta e blusa também preta de mangas curtas e gola alta.',
    contactLinks: {
      webLink: 'http://lattes.cnpq.br/3013921866070769'
    }
  },
  {
    id: 24,
    name: 'Aline Gonçalvez',
    role: 'Revisora de texto',
    photoSrc: require('../collaborators/aline-goncalvez.jpeg'),
    alt: 'Aline Gonçalvez é magra, tem pele clara, traços finos, olhos castanhos e cabelos castanhos claros, lisos e longos, repartidos de lado. Com um semblante sério, Aline está em frente a um corredor de paredes brancas e usa uma blusa preta de mangas longas e gola alta.',
    contactLinks: {
      instagram: 'https://www.instagram.com/aline.ogoncalves_/'
    }
  },
  {
    id: 25,
    name: 'Giovana Roberta Gonçalves Nogueira',
    role: 'Intérprete de Libras',
    photoSrc: require('../collaborators/giovana-roberta.jpeg'),
    contactLinks: {
      instagram: 'https://instagram.com/gi.gon.nog?utm_medium=copy_link'
    }
  },
  {
    id: 25,
    name: 'UNILEHU',
    role: 'Intérprete de Libras',
    photoSrc: require('../collaborators/unilehu.jpeg'),
    contactLinks: {
      webLink: 'https://www.unilehu.org.br'
    }
  },
  {
    id: 25,
    name: 'Cleiton Paulino',
    role: 'Intérprete de Libras',
    photoSrc: require('../collaborators/cleiton-paulino.jpeg'),
    contactLinks: {
      facebook: 'https://www.facebook.com/cleiton.paulino.965',
      webLink: 'https://www.facebook.com/futurosinterpretes/'
    }
  },
  {
    id: 25,
    name: 'Simone Malfra Batista dos Santos',
    role: 'Intérprete de Libras',
    photoSrc: require('../collaborators/simone-malfra.jpeg'),
    contactLinks: {}
  }
]
