import React from 'react'
import { Row } from 'react-grid-system'
import { FaGithub, FaInstagram, FaLinkedin, FaBehance, FaFacebook } from 'react-icons/fa'
import { FiGlobe } from 'react-icons/fi'
import { AiOutlineMail } from 'react-icons/ai'
import { ContentWrapper, Title, SubTitle, Text, Card, Column, Photo, Name, Role, Icons, ScreenReaderText } from './styles'
import { Navbar } from '../../components'
import collaborators from '../../assets/data/collaborators'
import voiceActors from '../../assets/data/voice-actors'

export default function Collaborators () {
  return (
    <>
      <Navbar />
      <ContentWrapper>
        <Title>Sobre nós</Title>
        <Text>
          Olá!<br />Somos Dani e Mari, estudantes de biologia e amantes da natureza. Por esta razão, gostaríamos de levar um pouquinho desse amor para as pessoas, especialmente para as crianças. Assim, pensamos em criar um aplicativo inclusivo, no qual todas as pessoas tenham acesso equivalente ao conhecimento. Tal ideia começou como um trabalho acadêmico, mas hoje faz parte de um sonho pessoal. Esperamos que o aplicativo Iara te proporcione uma boa experiência e que você se encante com a natureza.
        </Text>
        <Text>Qualquer dúvida, sugestão ou comentário pode entrar em contato conosco!</Text>
        <Text style={{ marginBottom: 0 }}>Vamos conhecer um pouquinho mais dos colaboradores que tornaram este aplicativo possível? Dê uma conferida aqui embaixo! <span role='img' aria-label='smile emoji'>🙂</span></Text>
      </ContentWrapper>
      <ContentWrapper>
        <SubTitle>Conheça quem torna esse projeto realidade!</SubTitle>
        <ScreenReaderText>Sobre fundo azul claro, fotos coloridas, até a altura do peito, dos colaboradores do aplicativo. Abaixo de cada fotografia, sobre um fundo branco, seguem o nome completo, a função no projeto e canais para contato com cada pessoa.</ScreenReaderText>
        <Row>
          {collaborators.map(collaborator => (
            <Column sm={12} md={6} lg={6} xl={4} key={collaborator.id}>
              <Card>
                <Photo src={collaborator.photoSrc} alt={collaborator.alt} />
                <Name>{collaborator.name}</Name>
                <Role>{collaborator.role}</Role>
                <Icons>
                  {collaborator.contactLinks.behance &&
                    <a href={collaborator.contactLinks.behance} rel='noopener noreferrer' target='_blank'><FaBehance /></a>}
                  {collaborator.contactLinks.email &&
                    <a href={'mailto:' + collaborator.contactLinks.email} rel='noopener noreferrer' target='_blank'><AiOutlineMail /></a>}
                  {collaborator.contactLinks.webLink &&
                    <a href={collaborator.contactLinks.webLink} rel='noopener noreferrer' target='_blank'><FiGlobe /></a>}
                  {collaborator.contactLinks.facebook &&
                    <a href={collaborator.contactLinks.facebook} rel='noopener noreferrer' target='_blank'><FaFacebook /></a>}
                  {collaborator.contactLinks.instagram &&
                    <a href={collaborator.contactLinks.instagram} rel='noopener noreferrer' target='_blank'><FaInstagram /></a>}
                  {collaborator.contactLinks.linkedin &&
                    <a href={collaborator.contactLinks.linkedin} rel='noopener noreferrer' target='_blank'><FaLinkedin /></a>}
                  {collaborator.contactLinks.github &&
                    <a href={collaborator.contactLinks.github} rel='noopener noreferrer' target='_blank'><FaGithub /></a>}
                </Icons>
              </Card>
            </Column>
          ))}
        </Row>
      </ContentWrapper>
      <ContentWrapper>
        <SubTitle>Dubladores</SubTitle>
        {voiceActors.map(voiceActor => (
          <Text style={{ textAlign: 'center', margin: 0 }} key={voiceActor.id}>
            {voiceActor.name} - {voiceActor.character}
          </Text>
        ))}
      </ContentWrapper>
    </>
  )
}
