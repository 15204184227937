import styled from 'styled-components'
import { Container, Col } from 'react-grid-system'
import { Colors, Fonts } from '../../constants'

export const Logo = styled.img`
    position: absolute;
    height: 70px;
    left: 20px;
    top: 20px;
    `

export const ContentWrapper = styled(Container)`
    text-align: center;
    margin-bottom: 50px;
    `

export const Column = styled(Col)`
    margin: 0;
    padding-bottom: 10px;
    padding-top: 30px;
    `

export const Title = styled.h1`
    font-size: 3em;
    margin-top: 50px;
    font-family: ${Fonts.title};
    color: ${Colors.darkBlue};
    `

export const SubTitle = styled.h2`
    font-size: 2.6em;
    margin-bottom: 20px;
    font-family: ${Fonts.title};
    color: ${Colors.darkBlue};
    `

export const Text = styled.p`
    margin: 50px 10px;
    font-weight: 600;
    text-align: justify;
    font-size: 20px;
    font-family: ${Fonts.text};
    color: ${Colors.darkBlue};
    `

export const Name = styled(Text)`
    margin: 0px;
    padding: 20px;
    padding-bottom: 0;
    text-align: center;
    color: ${Colors.black};
    `

export const Role = styled(Text)`
    margin: 0px;
    opacity: 0.6;
    font-size: 15px;
    color: ${Colors.black};
    text-align: center;
    color: ${Colors.black};
    `

export const Icons = styled.div`
    display: inline-block;
    padding: 15px;
    
    & > * {
        padding: 10px;
    }
    
    & > *:hover {
        color: ${Colors.blue};
    }
    `

export const Card = styled.div`
    background: ${Colors.white};
    width: 90%;
    padding: 0;
    margin: 0 auto;
    -webkit-box-shadow: 0px 0px 10px -6px rgba(0,0,0,0.82);
    -moz-box-shadow: 0px 0px 10px -6px rgba(0,0,0,0.82);
    box-shadow: 0px 0px 10px -6px rgba(0,0,0,0.82);
    border-radius: 30px;
    border-bottom: ${Colors.lightGray} 10px solid;
    min-height: auto;
`

export const Photo = styled.img`
    min-width: 100%;
    max-width: 100%;
    min-height: 300px;
    max-height: 300px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    object-fit: cover;
`

export const ScreenReaderText = styled.div`
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
`
