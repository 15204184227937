import React from 'react'
import { VideoWrapper } from './styles'

export default function VideoIframe (props) {
  return (
    <VideoWrapper>
      <iframe
        title={props.title}
        width='100%'
        height='100%'
        src={props.src}
        frameborder={props.frameBorder}
        allow={props.allow}
        allowfullscreen={props.allowFullScreen}
      />
    </VideoWrapper>
  )
}
