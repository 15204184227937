export default [
  {
    id: 1,
    name: 'Mapa bioma',
    src: 'https://escolaeducacao.com.br/biomas-brasileiros/'
  },
  {
    id: 2,
    name: 'Amazônia',
    src: 'https://bricspolicycenter.org/influencia-da-china-na-regiao-amazonica-e-tema-do-debates-chinos/ '
  },
  {
    id: 3,
    name: 'Boto-cor-de-rosa',
    src: 'https://greensavers.sapo.pt/boto-cor-de-rosa-o-maior-golfinho-de-agua-doce-esta-perigo-de-extincao/'
  },
  {
    id: 4,
    name: 'Açaí',
    src: 'http://www.viveiroipe.com.br/?mudas=acai'
  },
  {
    id: 5,
    name: 'Caatinga',
    src: 'http://www.sdr.ba.gov.br/node/6096'
  },
  {
    id: 6,
    name: 'Anta',
    src: 'http://www.zoologico.df.gov.br/anta/'
  },
  {
    id: 7,
    name: 'Ipê',
    src: 'https://br.pinterest.com/pin/448248969153232226/'
  },
  {
    id: 8,
    name: 'Pau-papel',
    src: 'https://cerradania.wordpress.com/2016/11/19/perfume-no-cerrado/'
  },
  {
    id: 9,
    name: 'Mata Atlântica',
    src: 'http://s2.glbimg.com/EH9Pgc9bQEDxCteBaDBk3drZ_rY=/s.glbimg.com/jo/g1/f/original/2013/05/30/floresta.jpg'
  },
  {
    id: 10,
    name: 'Mico-leão-dourado',
    src: 'https://pt.wikipedia.org/wiki/Mico-le%C3%A3o-dourado#/media/Ficheiro:Golden_lion_tamarin_portrait3.jpg'
  },
  {
    id: 11,
    name: 'Sapo-cururu',
    src: 'https://www.infoescola.com/wp-content/uploads/2014/03/DSC06557.jpg'
  },
  {
    id: 12,
    name: 'Tamanduá',
    src: 'https://www.flickr.com/photos/rgamboias/22988643486'
  },
  {
    id: 13,
    name: 'Cajueiro',
    src: 'https://pixy.org/src/14/143886.jpg'
  },
  {
    id: 14,
    name: 'Jabuticabeira',
    src: 'https://www.flickriver.com/photos/tags/pliniacauliflora/interesting/'
  },
  {
    id: 15,
    name: 'Pantanal',
    src: 'https://blog.biologiatotal.com.br/o-pantanal-brasileiro/'
  },
  {
    id: 16,
    name: 'Arara-azul',
    src: 'https://i1.wp.com/www.guiaestudo.com.br/wp-content/uploads/2019/06/Arara-Azul-Casal.jpg'
  },
  {
    id: 17,
    name: 'Jacaré',
    src: 'https://cdn.ambientes.ambientebrasil.com.br/wp-content/uploads/2020/11/jacare-2645898_1280-1024x682.jpg'
  },
  {
    id: 18,
    name: 'Tuiuiú',
    src: 'https://www.wikiaves.com.br/2641031&t=s&s=10182'
  },
  {
    id: 19,
    name: 'Palmeira Acuri',
    src: 'https://www.flickr.com/photos/imagens/6268957473'
  },
  {
    id: 20,
    name: 'Pampa',
    src: 'https://pt.wikipedia.org/wiki/Pampa#/media/Ficheiro:Esta%C3%A7%C3%A3o_Ecol%C3%B3gica_do_Taim.jpg'
  },
  {
    id: 21,
    name: 'Pica-pau-chorão',
    src: 'https://animais.culturamix.com/informacoes/aves/pica-pau-chorao-curiosidades-da-especie-e-foto'
  },
  {
    id: 22,
    name: 'Sapo-de-barriga-vermelha',
    src: 'https://ferramentas.sibbr.gov.br/ficha/bin/view/especie/melanophryniscus_admirabilis'
  },
  {
    id: 23,
    name: 'Tuco-tuco',
    src: 'https://www.flickr.com/photos/tatinski/9764726796'
  },
  {
    id: 24,
    name: 'Barba-de-bode',
    src: 'http://oextensionista.blogspot.com/2013/06/barba-de-bode-por-ir-cirilo-jose.html#.YHXp0uhKjIV'
  }
]
