export default [
  {
    id: 1,
    text: 'Açaí. Britannica Escola. Web. 2021.',
    src: 'https://escola.britannica.com.br/artigo/a%C3%A7a%C3%AD/483028'
  },
  {
    id: 2,
    text: 'Algarrobo. Jardim Botânico. 2013.',
    src: 'http://www.jb.fzb.rs.gov.br/conteudo/1455/?Algarrobo'
  },
  {
    id: 3,
    text: 'Anfíbios. Fundação Oswaldo Cruz. ',
    src: 'http://www.fiocruz.br/biosseguranca/Bis/infantil/anfibio.htm'
  },
  {
    id: 4,
    text: 'Anta. Britannica Escola. Web. 2021.',
    src: 'https://escola.britannica.com.br/artigo/anta/482636'
  },
  {
    id: 5,
    text: 'Arara-azul-grande. Wikiaves.',
    src: 'https://www.wikiaves.com.br/wiki/arara-azul-grande'
  },
  {
    id: 6,
    text: 'Biomas Brasileiros. IBGE Educa Jovens.',
    src: 'https://educa.ibge.gov.br/jovens/conheca-o-brasil/territorio/18307-biomas-brasileiros.html'
  },
  {
    id: 7,
    text: 'Boto-cor-de-rosa. Britannica Escola. Web. 2021.',
    src: 'https://escola.britannica.com.br/artigo/boto-cor-de-rosa/487777'
  },
  {
    id: 8,
    text: 'Caju. Britannica Escola. Web. 2021.',
    src: 'https://escola.britannica.com.br/artigo/caju/483145'
  },
  {
    id: 9,
    text: 'Cymerys, M. et al. Frutíferas e Plantas Úteis na Vida Amazônica. 2005.',
    src: 'http://www.cifor.org/publications/pdf_files/Books/BShanley1001/163_176.pdf'
  },
  {
    id: 10,
    text: 'Fonte, L.F.M. Sapinho-de-barriga-vermelha. 2015.',
    src: 'https://melanophryniscus.wixsite.com/admirabilis/curiosidades'
  },
  {
    id: 11,
    text: 'Gall, J. Barba-de-bode cresce em diferentes ambientes e tem utilidades medicinais. 2019.',
    src: 'https://agro20.com.br/barba-de-bode'
  },
  {
    id: 12,
    text: 'Gerando Biodiversidade. Native.',
    src: 'https://www.nativealimentos.com.br/sustentabilidade/biodiversidade/animais/mamiferos'
  },
  {
    id: 13,
    text: 'Gonçalves, G.L. Projeto tuco-tuco. 2005.',
    src: 'http://www.ufrgs.br/projetotucotuco/ctenomys.htm'
  },
  {
    id: 14,
    text: 'Guará - O grande lobo do Cerrado. WWF Brasil.',
    src: 'https://www.wwf.org.br/natureza_brasileira/especiais/biodiversidade/especie_do_mes/dezembro_lobo_guara.cfm'
  },
  {
    id: 15,
    text: 'Ipê. Britannica Escola. Web. 2021.',
    src: 'https://escola.britannica.com.br/artigo/ip%C3%AA/483303'
  },
  {
    id: 16,
    text: 'Jaburu. Britannica Escola. Web. 2021.',
    src: 'https://escola.britannica.com.br/artigo/jaburu/483305'
  },
  {
    id: 17,
    text: 'Jabuticabeira. Globo - Terra da Gente.',
    src: 'http://g1.globo.com/sp/campinas-regiao/terra-da-gente/flora/noticia/2015/02/jabuticabeira.html'
  },
  {
    id: 18,
    text: 'Jacaré. Britannica Escola. Web. 2021.',
    src: 'https://escola.britannica.com.br/artigo/jacar%C3%A9/483310'
  },
  {
    id: 19,
    text: 'Jacaré do Pantanal: Ficha Técnica e Peso. Portal dos animais. 2019.',
    src: 'https://www.portaldosanimais.com.br/informacoes/jacare-do-pantanal-ficha-tecnica-e-peso/'
  },
  {
    id: 20,
    text: 'Juazeiro. Agência Embrapa de Informação Tecnológica.',
    src: 'https://www.agencia.cnptia.embrapa.br/gestor/bioma_caatinga/arvore/CONT000g79856tg02wx5ok0wtedt3bn2rn65.html'
  },
  {
    id: 21,
    text: 'Lobo-guará. Britannica Escola. Web. 2021.',
    src: 'https://escola.britannica.com.br/artigo/lobo-guar%C3%A1/483339'
  },
  {
    id: 22,
    text: 'Mamíferos - euphractus sexcinctus - tatu-peba. ICMBio.',
    src: 'http://www.icmbio.gov.br/portal/faunabrasileira/estado-de-conservacao/7109-mamiferos-euphractus-sexcinctus-tatu-peba'
  },
  {
    id: 23,
    text: 'Mamiferos - Myrmecophaga tridactyla - tamanduá-bandeira. ICMBio.',
    src: 'http://www.icmbio.gov.br/portal/faunabrasileira/estado-de-conservacao/7127-mamiferos-myrmecophaga-tridactyla-tamandua-bandeira'
  },
  {
    id: 24,
    text: 'Mandacaru. Britannica Escola. Web. 2021.',
    src: 'https://escola.britannica.com.br/artigo/mandacaru/483356'
  },
  {
    id: 25,
    text: 'Mico-leão-dourado. AMLD - Associação Mico-Leão-Dourado.',
    src: 'http://www.micoleao.org.br/'
  },
  {
    id: 26,
    text: 'Mico-leão-dourado. Britannica Escola. Web. 2021.',
    src: 'https://escola.britannica.com.br/artigo/mico-le%C3%A3o/483386'
  },
  {
    id: 27,
    text: 'Mico-leão-dourado: o mascote da conservação da biodiversidade. WWF Brasil.',
    src: 'https://www.wwf.org.br/natureza_brasileira/especiais/biodiversidade/especie_do_mes/maio_mico_leao_dourado.cfm'
  },
  {
    id: 28,
    text: 'Pantanal. WWF Brasil.',
    src: 'https://www.wwf.org.br/natureza_brasileira/areas_prioritarias/pantanal/'
  },
  {
    id: 29,
    text: 'Piranha. Britannica Escola. Web. 2021.',
    src: 'https://escola.britannica.com.br/artigo/piranha/483469'
  },
  {
    id: 30,
    text: 'Piúva-do-campo ou Piúva-roxa. Que planta é essa? 2017.',
    src: 'http://qplantaeessa.blogspot.com/2015/04/piuva-do-campo-ou-piuva-roxa.html#.XnkgB-pKjIU'
  },
  {
    id: 31,
    text: 'Pica-pau-chorão. Wikiaves. 2020.',
    src: 'https://www.wikiaves.com.br/wiki/pica-pau-chorao'
  },
  {
    id: 32,
    text: 'Seriema. Wikiaves. 2020.',
    src: 'https://www.wikiaves.com.br/wiki/seriema'
  },
  {
    id: 33,
    text: 'Seriema. Parque das aves.',
    src: 'https://www.parquedasaves.com.br/aves/seriema/'
  },
  {
    id: 34,
    text: 'Tibouchina papyrus. Herbário Digital.',
    src: 'https://pirenopolis.tur.br/meioambiente/herbariodigital/Melastomataceae/Tibouchina/papyrus'
  },
  {
    id: 35,
    text: 'Tuiuiú. Wikiaves. 2020.',
    src: 'https://www.wikiaves.com.br/wiki/tuiuiu'
  },
  {
    id: 36,
    text: 'Veado-catingueiro. Britannica Escola. Web. 2021.',
    src: 'https://escola.britannica.com.br/artigo/veado-campeiro/483629'
  },
  {
    id: 37,
    text: 'Vitória-régia. Britannica Escola. Web. 2021.',
    src: 'https://escola.britannica.com.br/artigo/vit%C3%B3ria-r%C3%A9gia/483642'
  }
]
