import React from 'react'
import { Item, NavWrapper } from './styles'
import Media from 'react-media'

export default function Navbar () {
  const mapToPath = {
    0: '/',
    1: '/o-projeto',
    2: '/sobre-nos',
    3: '/referencias'
  }

  function getKeyByValue (object, value) {
    return Object.keys(object).find(key => object[key] === value)
  }

  function isActive (index) {
    const path = window.location.pathname
    if (parseInt(getKeyByValue(mapToPath, path)) === index) return 'true'
    else return undefined
  }

  return (
    <>
      <Media queries={{
        mobile: '(max-width: 1024px)'
      }}
      >
        {({ mobile }) => (
          <>
            <NavWrapper mobile={mobile}>
              <Item active={isActive(0)} to='/'>Início</Item>
              <Item active={isActive(1)} to='/o-projeto'>O projeto</Item>
              <Item active={isActive(2)} to='/sobre-nos'>Sobre nós</Item>
              <Item active={isActive(3)} to='/referencias'>Referências</Item>
            </NavWrapper>
          </>
        )}
      </Media>
    </>
  )
}
