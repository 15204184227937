import React from 'react'
import { ContentWrapper, Title, Text, Link, List, Item } from './styles'
import { Navbar } from '../../components'

export default function TheProject () {
  const userGuide = require('../../assets/guia-do-usuario.pdf')

  return (
    <>
      <Navbar />
      <ContentWrapper>
        <Title>O projeto</Title>
        <Text>
          O aplicativo Iara carrega um nome de origem tupi-guarani que pode ser interpretado como “Mãe das águas” e é uma alusão à indígena Iara, definida como símbolo desse aplicativo. A escolha do nome se deve ao fato de que a água é fundamental para todas as formas de vida na Terra e um dos nossos objetivos como autoras do projeto é apresentar a ampla biodiversidade que o Brasil abriga. Acreditamos ser de extrema importância que o símbolo do aplicativo seja um indígena, uma vez que esse povo representa o respeito e a ligação com a natureza brasileira que nós pretendemos transmitir para o público.
        </Text>
        <Text>
          No primeiro acesso ao aplicativo haverá uma breve introdução sobre a história de Iara. Logo após, serão apresentados os seis biomas brasileiros (Amazônia, Cerrado, Caatinga, Mata Atlântica, Pampa e Pantanal), os quais o usuário poderá escolher sobre qual deseja aprender primeiro. Cada bioma contém informações sobre três animais distintos e duas plantas típicas da região escolhida. Também terão acesso ao seu som, imagem, uma audiodescrição dos recursos visuais e a tradução do seu conteúdo em Libras. Ao final de cada bioma há um pequeno Quiz e uma história sobre a aventura da Iara.
        </Text>
        <Text>
          Abordando os biomas brasileiros, o projeto tem como objetivo promover a educação ambiental para o nosso público-alvo: as crianças de 8 a 10 anos. Tal iniciativa será feita de maneira acessível, de forma que a maioria das crianças sejam incluídas. Ademais, com o nosso guia para docentes e demais usuários, o aplicativo poderá ser utilizado como ferramenta didática e interativa para que o tema seja trabalhado com qualquer faixa etária.
        </Text>
        <Text>
          A importância de abordar a Educação Ambiental se deve ao fato de que o tema é um assunto interdisciplinar, que abrange áreas da História, Geografia e, principalmente, da Biologia. Além disso, a Educação Ambiental é fundamental para o nosso cotidiano, uma vez que faz parte de um processo de conscientização dos indivíduos sobre a conservação da natureza e da promoção da sustentabilidade.
        </Text>
        <List>
          <Item>
            <Link href={userGuide} target='_blank'>Guia do Usuário</Link>
          </Item>
          <Item>
            <Link href='https://forms.gle/3bYXDHFn5RssdpKZA' target='_blank'>Formulário de sugestões e comentários</Link>
          </Item>
        </List>
      </ContentWrapper>
    </>
  )
}
