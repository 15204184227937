import React from 'react'
import Home from './pages/Home/Home'
import TheProject from './pages/TheProject/TheProject'
import Collaborators from './pages/AboutUs/AboutUs'
import References from './pages/References/References'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

export default function Router () {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path='/o-projeto'>
            <TheProject />
          </Route>
          <Route exact path='/sobre-nos'>
            <Collaborators />
          </Route>
          <Route exact path='/referencias'>
            <References />
          </Route>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='/**'>
            <Redirect to='/' />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  )
}
