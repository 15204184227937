export const Colors = {
  black: '#000',
  white: '#fff',
  lightGray: '#e3e3e3',
  lightGreen: '#94ffbf',
  green: '#a6e9ff',
  darkBlue: '#002c57',
  darkBlueLighter: '#0051a1',
  blue: '#3742fa',
  lightBlue: '#a6e9ff',
  lightBlueLighter: '#c7f1ff'
}

export const Fonts = {
  title: "'Lalezar', cursive",
  text: "'Red Hat Display', sans-cript"
}
