import styled from 'styled-components'
import { Colors, Fonts } from '../../constants'

export const Content = styled.div`
    text-align: ${props => props.mobile ? 'center' : 'left'};
    margin-top: ${props => props.mobile ? '5%' : '12%'};
    margin-left: ${props => props.mobile ? '0%' : '20%'};
    background: 'red';
`

export const ContentWrapper = styled.div`
    width: 100%;
`

export const Title = styled.h1`
    margin: 0;
    margin-bottom: -30px;
    font-size: ${props => props.mobile ? '60px' : '100px'};
    margin-top: ${props => props.mobile ? '-30px' : 0};
    font-family: ${Fonts.title};
    color: ${Colors.darkBlue};
`

export const Text = styled.p`
    margin: 0;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    padding: ${props => props.mobile ? '20px' : '0'};
    font-family: ${Fonts.text};
    color: ${Colors.darkBlue};

    @media (max-height: 600px) {
        padding-top: 5px; 
        padding-bottom: 15px;
    }
`

export const TooltipText = styled.span`
    background: ${Colors.black};
    border-radius: 15px;
    padding: 10px 20px;
    margin: 70px;
    font-weight: 600;
    color: ${Colors.white};
    font-family: ${Fonts.text};
`

export const Download = styled.button`
    display: block;
    text-align: center;
    margin-left: ${({ mobile }) => mobile ? '50%' : ''};
    transform: ${({ mobile }) => mobile ? 'translateX(-50%)' : ''};
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
    margin-top: ${({ mobile }) => mobile ? 0 : '10px'};
    &:active, &:checked{
        opacity: 0.5;
    }
    box-shadow: 0px 10px 20px -8px rgba(0,0,0,0.45);
    font-family: ${Fonts.title}; 
    background: ${Colors.white};
    color: ${Colors.black};
    border-radius: 20px;
    height: auto;
    border: 10px solid rgba(255,255,255,0.3);
    border-style: groove;
    padding: 10px 25px;
    font-size: 35px;
    transition: 0.5s;
    &:hover{
      transform: scale(1.2) ${({ mobile }) => mobile ? 'translateX(-40%)' : ''};
    }
`

export const Iara = styled.img`
    position: absolute;
    margin-top: 0px;
    right: 5%;
    bottom: -200px;
    width: 500px;
    transform: rotate(-0deg);

    @media (max-width: 1365px) {
        display: none
    }
`

export const IaraMobile = styled.img`
    position: absolute;
    margin-top: 0;
    right: 0;
    bottom: -470px;
    width: 270px;
    transform: translateX(-15%);
    
    @media (min-width: 375px) {
        bottom: -550px;
    }
    @media (min-width: 768px) {
        width: 400px;
        bottom: -450px;
    }
`

export const ScreenReaderText = styled.div`
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
`
