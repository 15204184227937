import React from 'react'
import Media from 'react-media'
import { ContentWrapper, Title, Text, Content, Download, Iara, IaraMobile, ScreenReaderText } from './styles'
import { Navbar, VideoIframe } from '../../components'

export default function Home () {
  return (
    <>
      <ContentWrapper>
        <Navbar />

        <Media queries={{
          mobile: '(max-width: 1024px)'
        }}
        >
          {({ mobile }) => (
            <Content mobile={mobile}>
              <Title mobile={mobile}>iara</Title>
              <Text mobile={mobile}>Venha descobrir a natureza brasileira comigo!</Text>
              <Download mobile={mobile} onClick={() => window.open('http://explorar.iara.app')}>
                Explorar
              </Download>
              <br />
              <ScreenReaderText>Na animação a seguir, Iara é uma menina indígena magra e de pele morena. Ela tem cabelos negros e lisos, na altura dos ombros, e olhos pretos. Nas bochechas, tem pinturas vermelhas em formato de V. Ela usa um cocar com uma pena de arara-azul, um top no formato de uma faixa verde e um saiote laranja. Enquanto Iara fala, vão se alternando imagens suas sobre um fundo verde escuro e desenhos da menina na floresta Amazônica com membros de sua tribo e animais como a onça-pintada, o jacaré e a arara. No momento em que se ouvem trovões, vê-se a silhueta de animais fugindo em meio à tempestade. Quando Iara fala de sua viagem, a rota é tracejada no mapa do Brasil. Durante todo o vídeo, no canto inferior direito, há uma janela de Libras.</ScreenReaderText>
              <VideoIframe
                title='IaraApp: descobrindo a natureza brasileira'
                src='https://www.youtube.com/embed/TUNRl1-VTsc'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
              {mobile
                ? <IaraMobile alt='Imagem da índia Iara, protagonista do aplicativo' src={require('./../../assets/iara.png')} />
                : <Iara mobile={mobile} alt='Imagem da índia Iara, protagonista do aplicativo' src={require('./../../assets/iara.png')} />}
            </Content>
          )}
        </Media>
      </ContentWrapper>
    </>
  )
}
