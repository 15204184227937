export default [
  {
    id: 1,
    name: 'Daniela Mayumi Kita',
    character: 'Tatu-peba'
  },
  {
    id: 2,
    name: 'Danilo Cassinelli',
    character: 'Mico-leão-dourado'
  },
  {
    id: 3,
    name: 'Denner Dias Barros',
    character: 'Arara 1'
  },
  {
    id: 4,
    name: 'Gabriel Bertoletti Barozzi',
    character: 'Audiodescrição'
  },
  {
    id: 5,
    name: 'Guilherme Canassa',
    character: 'Fiscal Ambiental'
  },
  {
    id: 6,
    name: 'Guilherme Pupo Cândido',
    character: 'Boto-cor-de-rosa'
  },
  {
    id: 7,
    name: 'Mariana Pupo Cassinelli',
    character: 'Lobo-guará'
  },
  {
    id: 8,
    name: 'Mario Caccalano',
    character: 'Lenhador 2'
  },
  {
    id: 9,
    name: 'Matheus Pereira de Souza',
    character: 'Lenhador 1'
  },
  {
    id: 10,
    name: 'Melissa Pupo Cândido',
    character: 'Menina'
  },
  {
    id: 11,
    name: 'Victor Taniguti',
    character: 'Arara 2'
  }
]
