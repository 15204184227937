import styled from 'styled-components'

export const VideoWrapper = styled.div`
    margin: 20px 5%;
    width: 90%;
    height: 190px;
    
    @media (min-width: 400px) {
        height: 210px;
    }
    @media (min-width: 768px) {
        height: 390px;
    }
    @media (min-width: 1024px) {
        margin: 40px 0;
        width: 560px;
        height: 315px;
    }
`
