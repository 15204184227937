import styled from 'styled-components'
import { Colors, Fonts } from '../../constants'
import { Link } from 'react-router-dom'

export const NavWrapper = styled.div`
    margin: 20px auto;
    text-align: ${props => props.mobile ? 'center' : 'center'};
    width: ${props => props.mobile ? '100%' : '500px'};
    border-radius: 20px;
    & > * {
        display: inline-block;
    }
    &:last-child {
        margin-right: 40px;
    }
`

export const Item = styled(Link)`
    text-decoration: none;
    padding: 10px;
    margin: 0px;
    background: ${props => props.active ? Colors.lightBlueLighter : 'transparent'};
    border-radius: 50px;
    font-size: 20px;
    font-family: ${Fonts.text};
    font-weight: 600;
    color: ${Colors.darkBlue};
    padding: 10px 20px;
    cursor: pointer; 
    `
