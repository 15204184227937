import styled from 'styled-components'
import { Container } from 'react-grid-system'
import { Colors, Fonts } from '../../constants'

export const ContentWrapper = styled(Container)`
    text-align: center;
    margin-bottom: 50px;
    `

export const Title = styled.h1`
    font-size: 3em;
    margin-top: 50px;
    font-family: ${Fonts.title};
    color: ${Colors.darkBlue};
    `

export const SubTitle = styled.h2`
    font-size: 2.6em;
    margin: 20px 0;
    font-family: ${Fonts.title};
    color: ${Colors.darkBlue};
    `

export const List = styled.ul`
    list-style: none;
    
    @media(min-width: 768px) {
        column-count: ${props => props.wrapListIntoColumns ? 3 : 1}
    }
    `

export const Item = styled.li`
    margin: 0 10px 15px;
    font-weight: 600;
    text-align: justify;
    word-break: break-word;
    font-size: 20px;
    font-family: ${Fonts.text}; 
    color: ${Colors.darkBlue};
    `

export const Link = styled.a`
    color: ${Colors.darkBlueLighter};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
      }

    &:visited {
        color:${Colors.blue}
    }
    `
