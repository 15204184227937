export default [
  {
    id: 1,
    name: 'Amazônia',
    src: 'https://www.youtube.com/watch?v=CMCav51BW0E'
  },
  {
    id: 2,
    name: 'Boto-cor-de-rosa',
    src: 'https://www.youtube.com/watch?v=brUqoOABGw0'
  },
  {
    id: 3,
    name: 'Onça-pintada',
    src: 'https://www.youtube.com/watch?v=DBq7l79kbK0'
  },
  {
    id: 4,
    name: 'Piranha-vermelha',
    src: 'https://www.youtube.com/watch?v=JsZL-HYc2oY'
  },
  {
    id: 5,
    name: 'Caatinga',
    src: 'https://www.youtube.com/watch?v=-Zs16JFFELE'
  },
  {
    id: 6,
    name: 'Preá',
    src: 'https://www.youtube.com/watch?v=uVvY8bpCyLg'
  },
  {
    id: 7,
    name: 'Veado-catingueiro',
    src: 'https://www.youtube.com/watch?v=QwxP-1TW4NQ'
  },
  {
    id: 8,
    name: 'Tatu-peba',
    src: 'https://www.youtube.com/watch?v=WuOw0iWi890'
  },
  {
    id: 9,
    name: 'Cerrado',
    src: 'https://www.youtube.com/watch?v=43xG0RtMLVU'
  },
  {
    id: 10,
    name: 'Anta',
    src: 'https://www.youtube.com/watch?v=gKd9yosOMGs'
  },
  {
    id: 11,
    name: 'Lobo-guará',
    src: 'https://www.youtube.com/watch?v=irlf4aeXgrY'
  },
  {
    id: 12,
    name: 'Seriema',
    src: 'https://www.youtube.com/watch?v=ha6Wdd8cjm4'
  },
  {
    id: 13,
    name: 'Mata Atlântica',
    src: 'https://www.youtube.com/watch?v=_OEzLFRPW6c'
  }
]
