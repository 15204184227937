import React from 'react'
import { ContentWrapper, Title, SubTitle, List, Link, Item } from './styles'
import { Navbar } from '../../components'
import bibliographies from '../../assets/data/bibliography'
import sounds from '../../assets/data/entities-sounds'
import imgReferences from '../../assets/data/image-references'

export default function References () {
  return (
    <>
      <Navbar />
      <ContentWrapper>
        <Title>Referências</Title>
        <SubTitle>Informações</SubTitle>
        <List>
          {bibliographies.map(bibliography => (
            <Item key={bibliography.id}>{bibliography.text}
              &nbsp;Disponível em:&nbsp;
              <Link href={bibliography.src} target='_blank'>&lt;link&gt;</Link>.
            </Item>
          ))}
        </List>
        <SubTitle>Imagens</SubTitle>
        <List wrapListIntoColumns>
          {imgReferences.map(imgReference => (
            <Item key={imgReference.id}>
              {imgReference.name}:&nbsp;
              <Link href={imgReference.src} target='_blank'>&lt;link&gt;</Link>
            </Item>
          ))}
        </List>
        <SubTitle>Sons</SubTitle>
        <List wrapListIntoColumns>
          {sounds.map(sound => (
            <Item key={sound.id}>
              {sound.name}:&nbsp;
              <Link href={sound.src} target='_blank'>&lt;link&gt;</Link>
            </Item>
          ))}
        </List>
      </ContentWrapper>
    </>
  )
}
